const formRecruitment = document.querySelector("#formRecruitment");

if (formRecruitment) {
    formRecruitment.addEventListener("submit", async (e) => {
        const modal = bootstrap.Modal.getInstance(document.getElementById('recruitmentModalToggle'));
        e.preventDefault();

        var actionUrl = formRecruitment.getAttribute("action");

        var full_name = document.querySelector("input[name=full_name]").value;
        var email = document.querySelector("input[name=email]").value;
        var phone = document.querySelector("input[name=phone_number]").value;
        var linkCV = document.querySelector("input[name=link_cv]").value;

        var data = {
            full_name: full_name,
            email: email,
            phone_number: phone,
            note: "Đường dẫn đến CV:" + linkCV,
        };

        fetch(actionUrl, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const toast = new bootstrap.Toast(toastRecruitment)
        modal.hide();
        toast.show();
        formRecruitment.reset();
    });
}
