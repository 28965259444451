var logoutForm = document.getElementById('logout-form');
if (logoutForm) {
    logoutForm.addEventListener("submit", function(e) {
        e.preventDefault();

        var form = e.target;
        var actionUrl = form.getAttribute("action");

        var formData = new FormData(form);

        var xhr = new XMLHttpRequest();

        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function() {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    window.location.href = "/";
                }
                message = JSON.parse(this.response).message
            }
        });

        xhr.open("POST", actionUrl, true);

        xhr.send(formData);
    })
}
