require("./bootstrap");
require("./logout");
require("./formRecruitment");
$(document).ready(function () {
    $("#fullpage").fullpage({
        navigation: true,
        navigationPosition: "left",
        fixedElements: ".header",
        afterLoad: function (anchorLink, index) {
            if (index === 1) {
                $(".header").addClass("active");
            } else {
                $(".header").removeClass("active");
            }
        },
    });
});
headerHeight = document.querySelector(".header-height").offsetHeight;

const updateHeaderHeight = () => {
    var mainPaddingAuto = document.querySelector(".pjaut");

    if (mainPaddingAuto && headerHeight) {
        mainPaddingAuto.style.paddingTop = headerHeight + "px";
    }
};

document.addEventListener("DOMContentLoaded", () => {
    updateHeaderHeight();
    window.addEventListener("resize", updateHeaderHeight);
});

const isHomePage = document
    .querySelector("body")
    .classList.contains("home-page");
if (isHomePage) {
    const menuFooterCollapse = document.querySelectorAll(
        ".menu-item__collapse-button"
    );
    menuFooterCollapse.forEach((item) => {
        item.classList.remove("collapsed");
    });

    const contentFooterCollapse = document.querySelectorAll(".menu-item__child");

    contentFooterCollapse.forEach((item) => {
        item.classList.add("show");
    });
}

window.addEventListener('scroll', function () {
    var scrollPosition = window.scrollY;
    if (scrollPosition >= headerHeight) {
        $(".header").removeClass("active");
    } else {
        $(".header").addClass("active");
    }
});

const div = document.querySelector(".product-detail-action");

const newHTML = '<p class="mb-1 w-100"><i>Không còn size của bạn?  </i><a class="fw-bold text-black" type="button" data-bs-toggle="modal" data-bs-target="#orderNowModal">Đặt hàng ngay</a></p>';

div.insertAdjacentHTML("afterbegin", newHTML);

const btnOrderNow = document.querySelectorAll(".btn-product-detail-action");

for (let i = 0; i < btnOrderNow.length; i++) {
    if (btnOrderNow[i].hasAttribute('disabled')){
        btnOrderNow[i].remove();
    }
}
